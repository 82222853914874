import FacebookIcon from '@/assets/icons/social/facebook.svg'
import InstagramIcon from '@/assets/icons/social/instagram.svg'
import LinkedInIcon from '@/assets/icons/social/linkedin.svg'
import YouTubeIcon from '@/assets/icons/social/youtube.svg'
import XIcon from '@/assets/icons/social/x.svg'

export const getSocialIcon = (type: string) => {
  const iconMap: Record<string, string> = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    linkedin: LinkedInIcon,
    youtube: YouTubeIcon,
    x: XIcon,
  }
  return iconMap[type]
}

