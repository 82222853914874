import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'expert-profile',
    component: () => import('@/views/expert/profile/Profile.vue'),
  },
  {
    path: 'business-hours',
    name: 'expert-profile-business-hours',
    component: () => import('@/views/expert/profile/BusinessHours.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'edit',
    name: 'expert-profile-edit',
    component: () => import('@/views/expert/profile/ProfileEdit.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'edit-address',
    name: 'expert-profile-edit-address',
    component: () => import('@/views/expert/profile/ProfileEditAddress.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'settings',
    name: 'expert-profile-settings',
    component: () => import('@/views/expert/profile/ProfileSettings.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'users',
    name: 'expert-profile-users',
    component: () => import('@/views/expert/profile/Users.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'video-call',
    name: 'expert-profile-video-call',
    component: () => import('@/views/expert/profile/VideoCall.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
