import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'finances',
    component: () => import('./Finances.vue'),
  },
  {
    path: 'add-payment-method',
    name: 'finances-add-payment-method',
    component: () => import('./AddPaymentMethod.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'account-number',
    name: 'finances-account-number',
    component: () => import('./AccountNumber.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'invoices',
    name: 'finances-invoices',
    component: () => import('./Invoices.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'invoice-detail/:id',
    name: 'finances-invoice-detail',
    component: () => import('./InvoiceDetails.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'credit-detail/:id',
    name: 'finances-credit-detail',
    component: () => import('./CreditDetails.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'transactions',
    name: 'finances-transactions',
    component: () => import('./Transactions.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'subscriptions',
    name: 'finances-subscriptions',
    component: () => import('./Subscriptions.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
