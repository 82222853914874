import { defineComponent, h, ref, onMounted } from 'vue'

import ArrowLeftIcon from '@/assets/icons/arrow-left.svg?raw'
import ArrowRightIcon from '@/assets/icons/arrow-right.svg?raw'
import ArrowDownIcon from '@/assets/icons/arrow-down.svg?raw'
import CalendarIcon from '@/assets/icons/calendar.svg?raw'
import HeartIcon from '@/assets/icons/heart.svg?raw'
import HeartFillIcon from '@/assets/icons/heart-fill.svg?raw'
import MapIcon from '@/assets/icons/map.svg?raw'
import SearchIcon from '@/assets/icons/search.svg?raw'
import ShareIcon from '@/assets/icons/share.svg?raw'
import TriangleIcon from '@/assets/icons/triangle.svg?raw'

export type IconType =
  'arrowLeft' |
  'arrowRight' |
  'arrowDown' |
  'calendar' |
  'heart' |
  'heartFill' |
  'map' |
  'search' |
  'share' |
  'triangle'

export const IconMap: Record<IconType, string> = {
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowDown: ArrowDownIcon,
  calendar: CalendarIcon,
  heart: HeartIcon,
  heartFill: HeartFillIcon,
  map: MapIcon,
  search: SearchIcon,
  share: ShareIcon,
  triangle: TriangleIcon,
}

export const Icon = defineComponent({
  name: 'Icon',
  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  setup(props) {
    const wrapper = ref<HTMLElement | undefined>(undefined)

    onMounted(() => {
      const svg = wrapper.value?.firstChild as HTMLElement | undefined
      svg?.setAttribute('width', props.size.toString())
      svg?.setAttribute('height', props.size.toString())
    })

    return () => h('span', {
      ref: wrapper,
      // eslint-disable-next-line no-prototype-builtins
      innerHTML: IconMap.hasOwnProperty(props.type as IconType) ? IconMap[props.type as IconType] : props.type
    })
  },
})
