<template>
  <div @click="showModal = true" class="flex cursor-pointer">
    <span class="fi" :class="'fi-' + userStore.country?.toLowerCase()" />
    <VaIcon name="double_arrow" :rotation="90" :size="14" class="mx-3" />
  </div>

  <VaModal v-model="showModal" hide-default-actions close-button :title="t('side_menu.change_country')">
    <SelectList class="w-full" :items="selectItems" @selected-item="selectItem" />
  </VaModal>
</template>

<script lang="ts" setup>
import { VaIcon, VaModal } from 'vuestic-ui'
import { computed, onBeforeMount, ref } from 'vue'
import SelectList from '@/components/ui/form/select-list/SelectList.vue'
import { useUserStore } from '@/stores/UserStore'
import { useI18n } from 'vue-i18n'
import { useCacheStore } from '@/stores/CacheStore'
import CountryService from '@/services/CountryService'
import { replaceLocaleRouteSegment } from '@/utils/meta'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const cacheStore = useCacheStore()
const route = useRoute()
const router = useRouter()
const showModal = ref(false)
const userStore = useUserStore()

const emit = defineEmits<{
  (event: 'selectedCountry', country: string): void
}>()

onBeforeMount(() => {
  CountryService.getCountryList()
})

const selectItem = (value: string|null|undefined)  => {
  if (!value) return
  userStore.country = value
  showModal.value = false
  emit('selectedCountry', value)
  const newLocale = userStore.language + '-' + value
  const newRoute = { ...route, params: { ...route.params, locale: newLocale } }
  router.push(newRoute)
}

const selectItems = computed(() => {
  return cacheStore.countries.map((country) => ({
    iconClass: 'fi fi-' + country.short.toLowerCase(),
    label: country.name,
    value: country.short,
  }))
})
</script>