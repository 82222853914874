import { useToast, type ToastOptions, VaButton } from 'vuestic-ui'
import { EventBus } from '@/services/event-bus'
import { h, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw } from 'vue-router'

export function useToastDisplay() {
  const { close: closeToast, notify } = useToast()
  const stickyToasts: Record<string, string> = {}
  const { t } = useI18n()

  onMounted(() => {
    EventBus.subscribe('showToast', handleShowToast)
    EventBus.subscribe('showStickyToast', handleShowStickyToast)
    EventBus.subscribe('hideStickyToast', handleHideStickyToast)
  })

  onUnmounted(() => {
    EventBus.unsubscribe('showToast', handleShowToast)
    EventBus.unsubscribe('showStickyToast', handleShowStickyToast)
    EventBus.unsubscribe('hideStickyToast', handleHideStickyToast)
  })

  const handleHideStickyToast = (key: string) => {
    const name = stickyToasts[key]
    if (name) {
      closeToast(name)
      delete stickyToasts[key]
    }
  }

  const handleShowStickyToast = (options: { key: string, message: string, color: string, to?: RouteLocationRaw }) => {
    const name = notify({
      message: '',
      color: options.color,
      duration: 0,
      closeable: true,
      onClose: () => handleHideStickyToast(options.key),
      render: () => h('div', { class: 'flex flex-row gap-2' }, [
        h('span', { class: 'sticky-toast-message' }, t(options.message)),
        options.to ? h(VaButton, { plain: true, color: 'white', to: options.to, onClick: () => handleHideStickyToast(options.key) }, t('common.view')) : undefined,
      ]),
    })
    stickyToasts[options.key] = name!
  }

  const handleShowToast = (options: ToastOptions) => {
    notify({
      message: options.message ? t(options.message as string) : t('error_messages.common_error'),
      title: options.title ? t(options.title) : undefined,
      color: options.color,
      duration: options.duration,
    })
  }

  return {
  }
}