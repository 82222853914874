import PaginationModel from '@/models/responses/PaginationModel'
import ListRequest from '@/models/requests/ListRequest'
import Expert from '@/models/expert/Expert'
import ApiService from '@/services/ApiService'
import { SaveExpertRequest } from '@/models/requests/SaveExpertRequest'
import ApiResponse from '@/models/responses/ApiResponse'
import PetOwnerInvite from '@/models/pet-owner/PetOwnerInvite'
import { ExpertFindProfile } from '@/models/expert/ExpertFindProfile'
import Connection from '@/models/user/Connection'
import ExpertSendMessageRequest from '@/models/requests/ExpertSendMessageRequest'
import MessageAnalytics from '@/models/message/MessageAnalytics'

export default class ExpertService {
  private static url = 'expert'

  public static getList(queryParams: Partial<ListRequest>): Promise<PaginationModel<Expert>> {
    return ApiService.get<PaginationModel<Expert>>(this.url, queryParams)
  }

  public static getExpert(id: string): Promise<Expert> {
    return ApiService.get<ApiResponse<{ expert: Expert }>>(`${this.url}/${id}`)
      .then((response) => response.data.expert)
  }

  public static getInvitations(expertId: string): Promise<PaginationModel<PetOwnerInvite>> {
    return ApiService.get<PaginationModel<PetOwnerInvite>>(
      `${this.url}/${expertId}/invitations`,
      { page_length: 1000 }
    )
  }

  public static removeInvitation(expertId: string, id: string): Promise<void> {
    return ApiService.post<void>(`${this.url}/${expertId}/invitations/${id}/revoke`, {})
  }

  public static findMyProfile(params: Partial<ListRequest>): Promise<PaginationModel<ExpertFindProfile>> {
    return ApiService.get(`${this.url}/profile/find`, params)
  }

  public static claimProfile(expertId: string): Promise<PaginationModel<ExpertFindProfile>> {
    return ApiService.post(`${this.url}/${expertId}/profile/claim`, {})
  }

  public static connectToPetOwner(expertId: string, petOwnerId: string): Promise<Connection> {
    return ApiService.post<ApiResponse<{ connection: Connection }>>(`${this.url}/${expertId}/pet_owner/${petOwnerId}/connect`, {})
      .then((response) => response.data.connection)
  }

  public static deleteExpert(id: string): Promise<void> {
    return ApiService.delete(`${this.url}/${id}`, {})
  }

  public static sendInvite(id: string): Promise<Connection> {
    return ApiService.post<ApiResponse<{ connection: Connection }>>(`${this.url}/${id}/invite`, {})
      .then((response) => response.data.connection)
  }

  public static saveExpert(id: string|undefined, params: Partial<SaveExpertRequest>): Promise<Expert> {
    if (id) {
      return ApiService.put(`${this.url}/${id}`, params)
    } else {
      return ApiService.post(`${this.url}`, params)
    }
  }

  public static preRegisterExpert(params: { email: string }): Promise<void> {
    return ApiService.post(`${this.url}/preregister`, params)
  }

  public static sendMessage(params: ExpertSendMessageRequest): Promise<void> {
    return ApiService.post('message/send', params)
  }

  public static getMessageAnalytics(
    expertId: string,
    date_from: Date | null = null,
    date_to: Date | null = null
  ): Promise<MessageAnalytics[]> {
    return ApiService.get(`message/expert/${expertId}/analytics`, {
      date_from: date_from,
      date_to: date_to,
    })
  }
}