import mitt from 'mitt'
import { AppEvents } from '@/services/event-bus/AppEvents'

export class EventBus {
  private static emitter = mitt<AppEvents>()

  public static subscribe<Key extends keyof AppEvents>(type: Key, callback: (payload: AppEvents[Key]) => void) {
    this.emitter.on(type, callback)
  }

  public static unsubscribe<Key extends keyof AppEvents>(type: Key, callback: (payload: AppEvents[Key]) => void) {
    this.emitter.off(type, callback)
  }

  public static dispatch<Key extends keyof AppEvents>(type: Key, payload?: AppEvents[Key]): void {
    // @ts-ignore
    this.emitter.emit(type, payload)
  }
}
