import { onMounted, onUnmounted } from 'vue'
import { EventBus } from '@/services/event-bus'
import { useUserStore } from '@/stores/UserStore'
import Product from '@/models/product/Product'
import FeatureService from '@/services/FeatureService'
import { useCacheStore } from '@/stores/CacheStore'
import Expert from '@/models/expert/Expert'
import User from '@/models/user/User'
import Connection from '@/models/user/Connection'
import PetOwnerService from '@/services/PetOwnerService'
import ExpertService from '@/services/ExpertService'
import { AxiosError } from 'axios'
import { displayApiError, showStickyToast } from '@/utils/toast'
import { useRoute, useRouter } from 'vue-router'
import Action from '@/models/user/Action'
import MessageBroadcast from '@/models/message/MessageBroadcast'
import { useI18n } from 'vue-i18n'

export function useAppEventHandlers() {
  const cacheStore = useCacheStore()
  const route = useRoute()
  const router = useRouter()
  const userStore = useUserStore()
  const { t } = useI18n()

  onMounted(() => {
    EventBus.subscribe('.actionReceived', handleActionReceived)
    EventBus.subscribe('.connectionCreated', handleConnectionCreated)
    EventBus.subscribe('.connectionUpdated', handleConnectionUpdated)
    EventBus.subscribe('.expertUpdated', handleExpertUpdated)
    EventBus.subscribe('.featureStatusChanged', handleFeatureStatusChanged)
    EventBus.subscribe('.messageReceived', handleMessageReceived)
    EventBus.subscribe('.petOwnerUpdated', handlePetOwnerUpdated)
    EventBus.subscribe('.subscribed', handleSubscribed)
    EventBus.subscribe('.unsubscribed', handleUnsubscribed)
    EventBus.subscribe('.userUpdated', handleUserUpdated)
    EventBus.subscribe('httpError', handleHttpError)
  })

  onUnmounted(() => {
    EventBus.unsubscribe('.actionReceived', handleActionReceived)
    EventBus.unsubscribe('.connectionCreated', handleConnectionCreated)
    EventBus.unsubscribe('.connectionUpdated', handleConnectionUpdated)
    EventBus.unsubscribe('.expertUpdated', handleExpertUpdated)
    EventBus.unsubscribe('.featureStatusChanged', handleFeatureStatusChanged)
    EventBus.unsubscribe('.messageReceived', handleMessageReceived)
    EventBus.unsubscribe('.petOwnerUpdated', handlePetOwnerUpdated)
    EventBus.unsubscribe('.subscribed', handleSubscribed)
    EventBus.unsubscribe('.unsubscribed', handleUnsubscribed)
    EventBus.unsubscribe('.userUpdated', handleUserUpdated)
    EventBus.unsubscribe('httpError', handleHttpError)
  })

  const handleActionReceived = (action: Action) => {
    if (route.name === 'action-center') return
    showStickyToast(action.id, action.title, 'blud-500', { name: `action-center` })
    userStore.increaseUnreadActionCount()
  }

  const handleConnectionCreated = (connection: Connection) => {
    userStore.addConnection(connection)
  }

  const handleConnectionUpdated = (connection: Connection) => {
    userStore.addConnection(connection)
  }

  const handleExpertUpdated = (data: { id: string }) => {
    ExpertService.getExpert(data.id).then((expert: Expert) => {
      userStore.expertUpdated(expert)
    }).catch((error: AxiosError) => {
      displayApiError(error)
    })
  }

  const handleFeatureStatusChanged = (data: { feature: string; active: boolean }) => {
    cacheStore.features = []
    FeatureService.loadFeatures()
  }

  const handleHttpError = (status: number) => {
    switch (status) {
      case 0: // this commonly happens when there is no internet connection
      case 503:
        EventBus.dispatch('ServiceUnavailable')
        return
      case 401: // Unauthorized, this means the user is not logged in or the token is invalid or expired
        // navigate to login (except on Login / OTP page)
        if (route.name !== 'login' && route.name !== 'otp-login') {
          router.push({ name: 'login' })
          return
        }
        break
      case 403: // Forbidden, this means that user tried to access a page ore functionality which is not allowed
        // navigate to the main page
        router.push({ name: 'home' })
        return
      default:
    }
  }

  const handleMessageReceived = (message: MessageBroadcast) => {
    if (route.name === 'message-center-channel' && route.params.channelId === message.message_channel_id) return
    if (message.from_user_id === userStore.user?.id) return
    if (message.from_expert_id === userStore.expert()?.id) return

    const to = { name: 'message-center-channel', params: { channelId: message.message_channel_id } }
    const msg = t(`common.new_message_from_sender`, {
      sender: message.sender_name,
    })
    showStickyToast(message.id, msg, 'blue-500', to)
    userStore.increaseUnreadMessageCount()
  }

  const handlePetOwnerUpdated = (data: { id: string }) => {
    PetOwnerService.getPetOwner(data.id).then((petOwner) => {
      userStore.petOwnerUpdated(petOwner)
    }).catch((error: AxiosError) => {
      displayApiError(error)
    })
  }

  const handleSubscribed = (product: Product) => {
    userStore.addSubscription(product.key, product.frequency)
  }

  const handleUnsubscribed = (product: Product) => {
    userStore.removeSubscription(product.key, product.frequency)
  }

  const handleUserUpdated = (user: User) => {
    userStore.user = user
  }
}