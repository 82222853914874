<template>
  <div class="fixed bottom-0 left-4 right-4 z-[999999] h-[130px] flex flex-col p-4 bg-white rounded-t border border-gray-100" style="box-shadow: 0px -4px 10px rgb(42 74 100 / 15%);">
    <div class="flex flex-row items-center gap-2.5">
      <img src="/icon/icon-128x128.png" class="w-12 h-12" alt="Logo" />
      <div class="flex flex-col flex-1 gap-0.5">
        <span class="flex-1 text-md font-bold">{{ t('pwa.add_to_home_screen_title') }}</span>
        <span class="text-xs">rosypet.com</span>
      </div>
      <VaButton icon="close" @click="close" />
    </div>
    <VaButton class="m-4" @click="addToHomeScreen">
      {{ t('pwa.add_to_home_screen_button') }}
    </VaButton>
  </div>
</template>

<script lang="ts" setup>
import { VaButton } from 'vuestic-ui'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/UserStore'
import { useRouter } from 'vue-router'

const router = useRouter()
const userStore = useUserStore()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const close = () => {
  userStore.pwaPopoverShown = true
  emit('update:modelValue', false)
}

const addToHomeScreen = () => {
  router.push({ name: 'content', params: { key: 'homescreen-ios' } })
  close()
}
</script>
