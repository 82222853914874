import { AxiosError } from 'axios'
import ErrorData from '@/models/responses/ErrorData'
import { EventBus } from '@/services/event-bus'
import { RouteLocationRaw } from 'vue-router'

export const successToast = (message: string, title?: string) => {
  EventBus.dispatch('showToast', {
    message,
    title,
    color: 'success',
    duration: 3000,
  })
}

export const errorToast = (message: string, title?: string) => {
  EventBus.dispatch('showToast', {
    message,
    title,
    color: 'danger',
    duration: 3000,
  })
}

export const displayApiError = (error: AxiosError) => {
  if (error instanceof AxiosError) {
    const errorData = getServerErrorMessage(error.response?.data as ErrorData)
    void errorToast(errorData.msg, errorData.title)
  } else {
    void errorToast('error_messages.common_error', 'error_messages.error')
  }
}

export const getServerErrorMessage = (error: ErrorData): { title: string; msg: string } => {
  const title: string = error?.message ?? 'error_messages.error'

  let errorData: string | string[] = ''
  if (error?.data) {
    if (error.data.errors) {
      errorData = error.data.errors
    } else {
      errorData = error.data.errors
    }
  } else if (error?.errors) {
    errorData = error.errors
  } else if (error?.body && error.body?.text) {
    errorData = [error.body.text]
  }

  let errorMessage = ''
  if (errorData) {
    if (typeof errorData === 'string') {
      errorMessage = errorData
    } else {
      for (const key in errorData) {
        // eslint-disable-next-line no-prototype-builtins
        if (!errorData.hasOwnProperty(key)) {
          continue;
        }
        const errorList = errorData[key] as string[] | string
        if (typeof errorList === 'string') {
          errorMessage += errorList + '\n'
        } else {
          errorList.forEach((value) => {
            errorMessage += value + '\n'
          })
        }
      }
    }
  } else {
    errorMessage = error?.error
  }

  return { title, msg: errorMessage }
}

export const showStickyToast = (key: string, message: string, color: string, to?: RouteLocationRaw) => {
  EventBus.dispatch('showStickyToast', {
    key,
    message,
    color,
    to,
  })
}

export const hideStickyToast = (key: string) => {
  EventBus.dispatch('hideStickyToast', key)
}