<template>
  <div
    v-for="(item, index) of items"
    :key="index"
    class="px-3 py-1.5 gap-3 hover:bg-blue-100 cursor-pointer flex items-center"
    @click="emit('selectedItem', item.value)"
  >
    <img v-if="item.icon" class="w-7 h-7" :src="item.icon" />
    <span v-if="item.iconClass" class="w-7 h-7" :class="'item-icon-class ' + item.iconClass" />
    <div class="font-bold text-md">{{ item.label }}</div>
    <div class="grow" />
    <VaIcon v-if="item.expandable" name="chevron_right" :size="18" class="cursor-pointer" />
  </div>
</template>

<script lang="ts" setup generic="T">
import { VaIcon } from 'vuestic-ui'

export type SelectItem<T> = {
  expandable?: boolean,
  icon?: string|null,
  iconClass?: string,
  label: string|null,
  value?: T|null
}

defineProps<{
  items: SelectItem<T>[]
}>()

const emit = defineEmits<{
  (event: 'selectedItem', value: T|null|undefined): void
}>()
</script>