import type { RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router'
import UserService from '@/services/UserService'
import { hideSpinner, showSpinner } from '@/utils/spinner'
import { useUserStore } from '@/stores/UserStore'

export const userInfo = () => {
  return async (router: Router, to: RouteLocationNormalized, next: (location?: RouteLocationRaw) => void) => {
    const userStore = useUserStore()
    if (userStore.isLoggedIn() && to.name !== 'login' && to.name !== 'otp-login') {
      showSpinner()
      await UserService.getUserInfo().catch(() => {})
      hideSpinner()
    }
    return next()
  }
}
