import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'expert-pet-owners',
    component: () => import('./PetOwners.vue'),
  },
  {
    path: 'view/:id',
    name: 'expert-pet-owner',
    component: () => import('./PetOwner.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'invite',
    name: 'expert-invite',
    component: () => import('./Invite.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'invite/import',
    name: 'expert-invite-import',
    component: () => import('./InviteImport.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'invite/message',
    name: 'expert-invite-message',
    component: () => import('./InviteMessage.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'message',
    name: 'expert-message',
    component: () => import('./Message.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
