<template>
  <transition name="fade">
    <div v-if="isOpen" class="fixed w-full h-full flex flex-row z-[900000]">
      <div ref="background" class="grow bg-black opacity-25 cursor-pointer" @click="close" />
      <div ref="sideMenu" class="absolute h-full w-[350px] max-w-[100vw] right-0 overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="pt-4 bg-blue-600 grow">
            <div class="text-right h-10" style="margin-top: env(safe-area-inset-top)">
              <button
                class="inline-block m-1 mr-4 text-white"
                @click="close"
              >
                <VaIcon name="close" :size="28" />
              </button>
            </div>
            <div
              class="cursor-pointer py-0 px-4 flex flex-row items-center"
              v-if="userStore.user?.id && userStore.user?.role !== 'admin'"
              @click="openProfile"
            >
              <RoundImage class="w-[70px] h-[70px] mr-4" :image-url="userStore.userImageUrl()" :imageText="userStore.userFullName()" dashed />
              <span class="font-bold text-xs text-white flex-grow"> {{ userStore.userFullName() }}</span>
              <ShareButton
                @click="close"
                class="p-1"
                v-if="userStore.expert() && !userStore.petOwner() && expertUrl && userStore.expert()?.practice_name"
                icon-color="white"
                viewTitle="share.expert_view_title"
                :shareTitle="userStore.expert()?.practice_name ?? ''"
                shareDescription="share.expert_share_title"
                :share-url="expertUrl"
              />
              <ShareButton
                class="p-1"
                v-if="userStore.petOwner() && petOwnerUrl"
                iconColor="white"
                viewTitle="share.pet_owner_view_title"
                :share-title="userStore.userFullName() ?? ''"
                shareDescription="share.pet_owner_share_title"
                :share-url="petOwnerUrl"
              />
            </div>
            <div class="grow">
              <div class="m-4" />
              <div v-for="(menuItem, i) of menuItems" :key="i">
                <div
                  v-if="showMenuItem(menuItem)"
                  @click="menuItemTapped(menuItem, i)"
                  class="px-4 cursor-pointer w-[350px]"
                  :class="{ 'bg-blue-600': selectedRouteName === menuItem.to?.name || selectedMenuIndex === i }"
                >
                  <div class="h-10 flex flex-row items-center">
                    <span
                      class="font-bold text-xs uppercase"
                      :class="{
                        'text-blue-150': selectedRouteName !== menuItem.to?.name,
                        'text-pink-500': selectedRouteName === menuItem.to?.name,
                      }"
                    >
                      {{ t(menuItem.title!) }}
                    </span>
                    <div class="flex-1" />
                    <ArrowUpIcon v-if="menuItem.subMenus && menuItem.subMenus.length > 0 && selectedMenuIndex === i" />
                    <ArrowDownIcon v-if="menuItem.subMenus && menuItem.subMenus.length > 0 && selectedMenuIndex !== i" />
                  </div>
                  <div v-if="menuItem.subMenus && selectedMenuIndex === i">
                    <div v-for="(subMenu, subIndex) of menuItem.subMenus" :key="subIndex">
                      <div
                        v-if="showMenuItem(subMenu)"
                        class="pl-4 py-3 cursor-pointer"
                        @click="subMenuItemTapped(subMenu)"
                      >
                        <span
                          class="font-bold text-xs uppercase"
                          :class="{
                            'text-white': selectedRouteName !== subMenu.to?.name,
                            'text-pink-500': selectedRouteName === subMenu.to?.name,
                          }"
                        >
                          {{ t(subMenu.title!) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="h-[1px] border border-dashed border-[#2b5273]" />
                </div>
              </div>

              <div class="px-4 cursor-pointer w-[350px]">
                <div class="h-10 flex flex-row gap-4 items-center text-blue-150 text-xs uppercase">
                  <CountrySwitcher @selected-country="close" />
                  <LanguageSwitcher @selected-language="close" />
                  <div class="flex-1" />
                </div>
                <div class="h-[1px] border border-dashed border-[#2b5273]" />
              </div>
            </div>
          </div>

          <div class="p-4 bg-blue-600">
            <SocialMedia />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import ArrowUpIcon from '@/assets/icons/arrow_up.svg'
import ArrowDownIcon from '@/assets/icons/arrow_down.svg'
import CountrySwitcher from '@/components/side-menu/CountrySwitcher.vue'
import LanguageSwitcher from '@/components/side-menu/LanguageSwitcher.vue'
import SocialMedia from '@/components/side-menu/SocialMedia.vue'
import { useUserStore } from '@/stores/UserStore'
import { useRoute, useRouter } from 'vue-router'
import RoundImage from '@/components/RoundImage.vue'
import ShareButton from '@/components/ShareButton.vue'
import slug from 'slug'
import { AdminMenus, IntroMenus, PetExpertMenus, PetOwnerMenus } from '@/components/side-menu/menu'
import { IMenu } from '@/models/IMenu'
import { EventBus } from '@/services/event-bus'
import { useCacheStore } from '@/stores/CacheStore'
import { useI18n } from 'vue-i18n'
import { replaceLocaleRouteSegment } from '@/utils/meta'
import { hideSpinner, showSpinner } from '@/utils/spinner'
import UserService from '@/services/UserService'
import { showConfirmPopup } from '@/utils/confirm-popup'
import { VaIcon } from 'vuestic-ui'

const background = ref(null)
const cacheStore = useCacheStore()
const isOpen = ref(false)
const route = useRoute()
const router = useRouter()
const selectedRouteName = ref('')
const selectedMenuIndex = ref<number|undefined>(undefined)
const sideMenu = ref(null)
const userStore = useUserStore()
const { t } = useI18n()

onMounted(() => {
  EventBus.subscribe('openSideMenu', () => { isOpen.value = true })
  EventBus.subscribe('closeSideMenu', () => { isOpen.value = false })
})

const close = () => {
  isOpen.value = false
}

const expertUrl = computed((): string|undefined => {
  return userStore.expert() ? `/search/expert/${userStore.expert()?.id}/${slug(userStore.expert()?.practice_name ?? '')}` : undefined
})

const logout = async (): Promise<void> => {
  const confirm = await showConfirmPopup('common.logout_confirm')
  if (!confirm) {
    return
  }
  showSpinner()
  if (UserService.getDeviceId()) {
    UserService.removeDevice()
      .then(() => {
        logOutUser()
      }).catch(() => {
        logOutUser()
      })
  } else {
    logOutUser()
  }
}

const logOutUser = (): void => {
  UserService.logout().catch(() => {
    // ignore
  }).finally(() => {
    hideSpinner()
    router.push({ name: 'login' })
  })
}

const menuItems = computed(() => {
  switch (userStore.user?.role) {
    case 'expert':
      return PetExpertMenus
    case 'petowner':
      return PetOwnerMenus
    case 'admin':
      return AdminMenus
    default:
      return IntroMenus
  }
})

const menuItemTapped = (menuItem: IMenu, i: number) => {
  if (!menuItem.subMenus || menuItem.subMenus.length < 1) {
    close()
  }
  if (menuItem.title === 'menu.logout') {
    logout()
    return
  }
  if (menuItem.subMenus && menuItem.subMenus.length > 0) {
    if (selectedMenuIndex.value === i) {
      selectedMenuIndex.value = undefined
    } else {
      selectedMenuIndex.value = i
    }
    return
  }
  if (menuItem.to) {
    router.push(menuItem.to)
  }
}

const open = () => {
  isOpen.value = true
}

const openProfile = (): void => {
  close()
  if (userStore.user?.role === 'petowner') {
    router.push({ name: 'pet-owner.profile' })
  } else {
    router.push({ name: 'expert.profile' })
  }
}

const petOwnerUrl = computed((): string|undefined => {
  return userStore.petOwner() ? `/search/pet-owner/${userStore.petOwner()?.id}/${slug(userStore.userFullName() ?? '')}` : undefined
})

const showMenuItem = (menuItem: IMenu): boolean => {
  if (!menuItem.feature) return true
  return cacheStore.isFeatureActive(menuItem.feature)
}

const subMenuItemTapped = (menuItem: IMenu) => {
  close()
  if (menuItem.to) {
    router.push(menuItem.to)
  }
}

watch(() => route.name, value => {
  if (!value) return
  selectedRouteName.value = value.toString()
  selectedMenuIndex.value = menuItems.value.findIndex(
    (el) => el.subMenus && el.subMenus.filter((el1) => el1.to?.name === selectedRouteName.value).length > 0
  )
}, { immediate: true })
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>