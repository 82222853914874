import axios, { AxiosError, AxiosProgressEvent } from 'axios'
import { useUserStore } from '@/stores/UserStore'
import Config from '@/utils/config'
import { fixDateFields } from '@/utils/fix-date-fields'
import { EventBus } from '@/services/event-bus'

export default class ApiService {
  public static getAuthHeader(): { Authorization: string } | object {
    const userStore = useUserStore()
    const token = userStore.token
    if (!token) {
      return {}
    }
    return {Authorization: `Bearer ${token}`}
  }

  /* eslint-disable @typescript-eslint/no-unsafe-argument */

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected static serialize(obj: object): string {
    const str = []
    for (const p in obj) {
      // @ts-ignore
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(p) && obj[p] != undefined && obj[p] != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        // @ts-ignore
        const elem = obj[p]
        if (Array.isArray(elem)) {
          for (const value of elem) {
            if (value instanceof Date) {
              str.push(
                encodeURIComponent(p) +
                '=' +
                encodeURIComponent(value.toISOString())
              )
            } else {
              str.push(encodeURIComponent(p) + '=' + encodeURIComponent(value))
            }
          }
        } else if (elem instanceof Date) {
          str.push(
            encodeURIComponent(p) + '=' + encodeURIComponent(elem.toISOString())
          )
        } else {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(elem))
        }
      }
    }
    /* eslint-enable */
    return str.join('&')
  }

  public static async delete<R>(url: string, params = {}): Promise<R> {
    return this.request('DELETE', url, params, {})
  }

  public static async get<R>(url: string, params = {}, options?: { stream?: boolean; formData?: boolean }): Promise<R> {
    return this.request('GET', url, params, {}, options)
  }

  public static async post<R>(
    url: string,
    data = {},
    options?: { stream?: boolean; formData?: boolean },
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<R> {
    return this.request('POST', url, {}, data, options, onUploadProgress)
  }

  public static async put<R>(url: string, data = {}): Promise<R> {
    return this.request('PUT', url, {}, data)
  }

  public static async request<R>(
    method: 'DELETE' | 'GET' | 'POST' | 'PUT',
    url: string,
    params = {},
    dataObj = {},
    options?: { stream?: boolean; formData?: boolean },
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<R> {
    const userStore = useUserStore()
    const newParams = {...params, lang: userStore.language}
    const queryParam = this.serialize(newParams)

    const authHeader = url.startsWith('http') ? {} : this.getAuthHeader()
    const useFormData = this.containsFile(dataObj) || options?.formData

    let data: object | FormData
    if (useFormData) {
      data = this.jsonToFormData(dataObj)
    } else {
      data = dataObj
    }

    return axios.request({
      method,
      url:
        (url.startsWith('http') ? '' : Config.apiUrl) + url + '?' + queryParam,
      headers: {...authHeader},
      data,
      responseType: options?.stream ? 'blob' : 'json',
      onUploadProgress,
    }).then((response) => {
      fixDateFields(response.data)
      return response.data
    }).catch((error: AxiosError) => {
      EventBus.dispatch('httpError', error.response?.status)
      throw error
    })
  }

  protected static containsFile(data: object): boolean {
    return !!Object.values(data).find(
      (value) =>
        value instanceof Blob ||
        value instanceof File ||
        (value instanceof Array &&
          value.find((v) => v instanceof Blob || v instanceof File))
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  protected static jsonToFormData(data: any): FormData {
    const formData = new FormData()

    this.buildFormData(formData, data)

    return formData
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  protected static buildFormData(
    formData: FormData,
    data: any,
    parentKey: string | null = null
  ): void {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      Object.keys(data as object).forEach((key) => {
        if (data[key]) {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          )
        }
      })
    } else if (typeof data === 'boolean') {
      formData.append(parentKey ?? '', data ? '1' : '0')
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value: string = data == null ? '' : data

      formData.append(parentKey ?? '', value)
    }
  }
}