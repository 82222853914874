import { RouteRecordRaw } from 'vue-router'
import PetRoutes from './pets/routes'
import ProfileRoutes from './profile/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'my-experts',
    name: 'pet-owner-experts',
    component: () => import('./Experts.vue'),
  },
  {
    path: 'my-pets',
    children: PetRoutes,
  },
  {
    path: 'profile',
    children: ProfileRoutes,
  },
]

export default routes
