import { type Router, type RouteLocationNormalized, type RouteLocationRaw } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import Config from '@/utils/config'

export const expertOnboarding = () => {
  return (router: Router, to: RouteLocationNormalized, next: (location?: RouteLocationRaw) => void) => {
    if (Config.ssr) return next()

    const userStore = useUserStore()
    if (userStore.user?.role !== 'expert') return next()

    const expert = userStore.expert()
    if (!expert) {
      return next({ name: 'onboarding-expert-find-profile' })
    }
    if (!expert.reviewed_by_user) {
      return next({ name: 'onboarding-expert-profile' })
    }

    return next()
  }
}
