import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'pet-owner-pets',
    component: () => import('./Pets.vue'),
  },
  {
    path: 'create',
    name: 'pet-owner-pet-create',
    component: () => import('./Pet.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: ':id',
    name: 'pet-owner-pet-edit',
    component: () => import('./Pet.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
