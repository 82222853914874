import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'message-center',
    component: () => import('./MessageCenter.vue'),
  },
  {
    path: ':channelId',
    name: 'message-center-channel',
    component: () => import('./MessageCenter.vue'),
    meta: { header: { hideHeaderOnMobile: true } }
  },
  {
    path: ':channelId/messages/:messageId',
    name: 'message-center-message',
    component: () => import('./MessageCenter.vue'),
    meta: { header: { hideHeaderOnMobile: true } }
  },
]

export default routes
