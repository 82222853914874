import { defineAsyncComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { EventBus } from '@/services/event-bus'
import { useUserStore } from '@/stores/UserStore'
import Config from '@/utils/config'

export type OverlayPopupOptions = {
  titleTextKey: string
  htmlTextKey: string
  buttons: { key: string; handler?: () => void; textKey: string }[]
}

export const overlayPopups = ref<string[]>([])
export const overlayPopupOptions = ref<Record<string, OverlayPopupOptions>>({})

export function useOverlayPopup() {
  const OverlayPopup = defineAsyncComponent(() =>
    import('@/components/OverlayPopup.vue')
  )

  const userStore = useUserStore()

  onMounted(() => {
    EventBus.subscribe('.showOverlayPopup', handleShowOverlayPopup)
    showCookiePopup()
  })

  onUnmounted(() => {
    EventBus.unsubscribe('.showOverlayPopup', handleShowOverlayPopup)
  })

  const closeOverlayPopup = (key: string) => {
    if (!key) return
    delete overlayPopupOptions.value[key]
    overlayPopups.value = overlayPopups.value.filter(
      (k) => k !== key
    )
  }

  const handleShowOverlayPopup = (options: OverlayPopupOptions) => {
    const key = Math.random().toString(36).substring(7)
    overlayPopupOptions.value[key] = options
    overlayPopups.value.push(key)
  }

  const showCookiePopup = () => {
    if (Config.ssr) return
    const cookieAcceptTime = userStore.getCookieAcceptTime()
    const isCookieAccepted = !!cookieAcceptTime
    if (!isCookieAccepted && Config.environment !== 'e2e') {
      setTimeout(() => {
        handleShowOverlayPopup({
          titleTextKey: 'cookie.title',
          htmlTextKey: 'cookie.description',
          buttons: [
            {
              key: 'accept',
              textKey: 'cookie.accept',
              handler: () => {
                userStore.setCookieAcceptTime(new Date())
              },
            },
          ],
        })
      }, 3000)
    }
  }

  watch(() => userStore.getCookieAcceptTime(), (cookieAcceptTime) => {
    if (import.meta.env.SSR) return
    const isCookieAccepted = !!cookieAcceptTime
    // @ts-ignore
    window[`ga-disable-${Config.googleAnalyticsTag}`] = !isCookieAccepted
  }, { immediate: true })

  return {
    OverlayPopup,
    closeOverlayPopup,
    overlayPopups,
  }
}
