import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'search',
    component: () => import('./Search.vue'),
  },
  {
    path: 'category',
    name: 'search-categories',
    component: () => import('./SearchCategories.vue'),
  },
  {
    path: 'category/:category',
    name: 'search-category',
    component: () => import('./SearchCategories.vue'),
  },
  {
    path: 'category/:category/in/:countryCode',
    name: 'search-category-in-country',
    component: () => import('./SearchCategories.vue'),
  },
  {
    path: 'category/:category/in/:countryCode/:regionId',
    name: 'search-category-in-region',
    component: () => import('./SearchCategories.vue'),
  },
  {
    path: 'category/:category/in/:countryCode/:city',
    name: 'search-category-in-city',
    component: () => import('./Search.vue'),
  },
  {
    path: ':type',
    name: 'search-type',
    component: () => import('./Search.vue'),
  },
  {
    path: 'expert/:id/:slug?',
    name: 'search-expert',
    component: () => import('./ExpertDetails.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
