<template>
  <VaModal
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    size="small"
    :title="title"
    close-button
    @close="close"
    hide-default-actions
  >
    <div v-for="(button, index) in buttons" :key="index" class="border-t border-t-[#00305635]">
      <button @click="button.handler(); close()" class="w-full p-4 text-lg">{{ button.text }}</button>
    </div>
  </VaModal>
</template>

<script lang="ts" setup>
import { VaModal } from 'vuestic-ui'

withDefaults(defineProps<{
  buttons: Array<{
    text: string,
    handler: () => void,
  }>,
  modelValue: boolean,
  title: string|undefined,
}>(), {
  title: undefined,
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'submitted'): void
  (e: 'update:modelValue', value: boolean): void
}>()

const close = () => {
  emit('close')
  emit('update:modelValue', false)
}
</script>