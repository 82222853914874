<template>
  <div @click="showModal = true" class="flex cursor-pointer">
    <span>{{ t('language.' + userStore.language) }}</span>
    <VaIcon name="double_arrow" :rotation="90" :size="14" class="mx-3" />
  </div>

  <VaModal v-model="showModal" hide-default-actions close-button :title="t('side_menu.change_language')">
    <SelectList class="w-full" :items="selectItems" @selected-item="selectItem" />
  </VaModal>
</template>

<script lang="ts" setup>
import { VaIcon, VaModal } from 'vuestic-ui'
import { computed, ref } from 'vue'
import SelectList from '@/components/ui/form/select-list/SelectList.vue'
import { useUserStore } from '@/stores/UserStore'
import { useI18n } from 'vue-i18n'
import Config from '@/utils/config'
import { replaceLocaleRouteSegment } from '@/utils/meta'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const showModal = ref(false)
const userStore = useUserStore()

const emit = defineEmits<{
  (event: 'selectedLanguage', language: string): void
}>()

const selectItem = (value: string|null|undefined)  => {
  if (!value) return
  userStore.language = value
  showModal.value = false
  emit('selectedLanguage', value)
  const newLocale = userStore.language + '-' + value
  const newRoute = { ...route, params: { ...route.params, locale: newLocale } }
  router.push(newRoute)
}

const selectItems = computed(() => {
  return Config.appLocales
    .filter((locale) => !locale.adminOnly)
    .filter((locale, index, self) => self.findIndex((l) => l.language === locale.language) === index)
    .map((locale) => ({
    label: t('language.' + locale.language),
    value: locale.language,
  }))
})
</script>