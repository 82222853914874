import ApiResponse from '@/models/responses/ApiResponse';
import ApiService from '@/services/ApiService';
import Country from '@/models/country/Country'
import { useCacheStore } from '@/stores/CacheStore'
import { subDays } from 'date-fns'
import Config from '@/utils/config'

export default class CountryService {
  private static url = 'country'

  public static async getCountryList(): Promise<Country[]> {
    const cacheStore = useCacheStore()
    if (
      cacheStore.countries?.length
      && cacheStore.getCountriesCacheTime()
      && cacheStore.getCountriesCacheTime()! > subDays(new Date(), Config.cacheDays)
    ) {
      return Promise.resolve(cacheStore.countries)
    }

    return ApiService.get<ApiResponse<Country[]>>(`${this.url}`).then((response) => {
      cacheStore.countries = response.data ?? []
      cacheStore.setCountriesCacheTime(new Date())
      return cacheStore.countries
    })
  }

  public static async getCountryByCode(code: string): Promise<Country | undefined> {
    const countries = await this.getCountryList()
    return countries.find(item => item.short === code)
  }
}