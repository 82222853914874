<template>
  <Button
    :light="props.light"
    :outline="props.outline"
    :rounded="props.rounded"
    :size="props.size"
    :variant="props.variant"
    class="inline-flex items-center justify-center"
    :class="{
      'flex-row-reverse': props.reverse
    }"
  >
    <Icon :type="props.icon" :size="props.iconSize" />
    <slot>
      <span
        :class="{
          'ml-1': !props.reverse,
          'mr-1': props.reverse,
        }"
      >
        {{ props.label }}
      </span>
    </slot>
  </Button>
</template>

<script lang="ts" setup>
  import { Button, ButtonSize, ButtonVariant, Icon } from "@/components"

  export interface Props {
    icon?: string
    iconSize?: number
    reverse?: boolean
    label?: string
    light?: boolean
    outline?: boolean
    rounded?: boolean
    size?: ButtonSize
    variant?: ButtonVariant
  }

  const props = withDefaults(defineProps<Props>(), {
    icon: '',
    iconSize: 24,
    reverse: false,
    label: '',
    light: false,
    rounded: false,
    outline: false,
    size: () => ButtonSize.DEFAULT,
    variant: () => ButtonVariant.PRIMARY,
  })
</script>