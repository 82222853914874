import Geolocation from '@/models/Geolocation'
import process from 'node:process'

export default class Config {
  private static env: Record<string, any> = {}
  private static construct = this.init()

  static NO_IMAGE_URL = '/assets/img/placeholder/placeholder.png'
  static apiUrl = this.env.VITE_API_URL as string
  static appBasePath = this.env.VITE_APP_BASE_PATH as string|undefined
  static appLocales: ILocale[] = this.env.VITE_APP_LOCALES ? JSON.parse(this.env.VITE_APP_LOCALES as string) : []
  static appLocation = this.env.VITE_APP_LOCATION ? JSON.parse(this.env.VITE_APP_LOCATION) as Geolocation : undefined
  static availableCountries: string[] = this.env.VITE_AVAILABLE_COUNTRIES ? JSON.parse(this.env.VITE_AVAILABLE_COUNTRIES as string) : []
  static baseUrl: string = this.env.VITE_BASE_URL as string
  static cacheDays = 7
  static client_timestamp: string = this.env.VITE_CLIENT_TIMESTAMP as string
  static client_version: string = this.env.VITE_CLIENT_VERSION as string
  static contentDomain: string = this.env.VITE_CONTENT_DOMAIN as string
  static environment: string = this.env.VITE_ENV as string
  static fileUploadMaxSize = 10
  static googleAnalyticsTag: string = this.env.VITE_GOOGLE_ANALYTICS_TAG as string
  static googleMapsApiKey: string = this.env.VITE_GOOGLE_MAPS_API_KEY as string
  static languages: { lang: string; top: boolean }[] = this.env.VITE_LANGUAGES ? JSON.parse(this.env.VITE_LANGUAGES as string) : []
  static mauticBaseUrl?: string = this.env.VITE_MAUTIC_BASE_URL as string
  static oneSignalAppId: string = this.env.VITE_ONESIGNAL_APP_ID as string
  static production: boolean = this.env.VITE_PRODUCTION === 'true'
  static sentryDSN: string = this.env.VITE_SENTRY_DSN as string
  static socialMedia?: { country?: string, language?: string, type: string, url: string }[] = this.env.VITE_SOCIAL_MEDIA ? JSON.parse(this.env.VITE_SOCIAL_MEDIA as string) : []
  static socketKey: string = this.env.VITE_SOCKET_KEY as string
  static socketHost: string = this.env.VITE_SOCKET_HOST as string
  static socketPort: string = this.env.VITE_SOCKET_PORT as string
  static socketAuthEndpoint: string = this.env.VITE_SOCKET_AUTH_ENDPOINT as string
  static socketTls: boolean = this.env.VITE_SOCKET_TLS === 'true'
  static ssr = import.meta.env.SSR
  static stripeKey: string = this.env.VITE_STRIPE_KEY as string
  static videoCallCommission = 0.19

  static getAdminLanguages(): string[] {
    return [...new Set(this.appLocales.map(locale => locale.language))].sort()
  }

  private static init() {
    if (import.meta.env.SSR && process.env.NODE_ENV === 'production') {
      Config.env = Object.keys(process.env)
        .filter((key) => key.startsWith('VITE_'))
        .reduce((env, key) => {
          // @ts-ignore
          env[key] = process.env[key]
          return env
        }, {})
    } else {
      Config.env = import.meta.env
    }
    return undefined
  }

  private static afterConstruct = (function() {
    // @ts-ignore
    if (!import.meta.env.SSR && window['Config']) {
      // @ts-ignore
      const config = window['Config'] as Record<string, any>
      config['ssr'] = false
      Object.keys(config).forEach(key => {
        // @ts-ignore
        Config[key] = config[key]
      })
    }
    return undefined
  })()
}

export interface ILocale {
  language: string
  country?: string|undefined
  datePickerFormat: string
  timePickerFormat: string
  adminOnly?: boolean
}
